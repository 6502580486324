<template>
  <section class="d-flex align-center">
    <v-menu
      bottom
      left
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="text-capitalize mx-4 d-none d-md-inline-flex"
          icon
          text
          v-bind="attrs"
          x-small
          v-on="on"
        >
          <v-avatar size="40">
            <v-img
              :src="profile.thumbnailImageURL"
            />
          </v-avatar>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, idx) in navItems"
          :key="`app-bar-dropdown-item-${idx}`"
          :input-value="false"
          :to="item.route"
          exact-path
          color="dark"
          class="disabled-active-link"
        >
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-btn
      v-if="isMobileView && !searchActive"
      key="search-button"
      class="mr-3 d-md-none"
      color="primary"
      dark
      fab
      small
      @click="toggleSearchActive"
    >
      <v-icon dark size="15">
        mdi-magnify
      </v-icon>
    </v-btn>
    <v-text-field
      v-else
      key="search-button-trigger"
      solo
      flat
      class="d-flex mr-3 d-md-none"
      prepend-inner-icon="mdi-magnify"
      append-icon="mdi-close-circle"
      placeholder="Search"
      hide-details
      dense
      @click:append="toggleSearchActive"
      @keydown.enter="search"
    />

    <v-btn
      color="primary"
      dark
      small
      @click="login"
    >
      <v-icon dark size="15"
      >
        mdi-wallet
      </v-icon>
    </v-btn>

    <v-btn
      class="d-md-none"
      color="white"
      icon
      x-large
      @click="toggleMobileDrawer"
    >
      <v-icon>
        mdi-menu
      </v-icon>
    </v-btn>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'AppBarActions',
  data: () => ({
    searchActive: false,
    navItems: [
      {
        text: 'Profile',
        route: '/myProfile',
      },
      {
        text: 'Favourites',
        route: '/myProfile?tab=favourites',
      },
      {
        text: 'My Collection',
        route: '/myProfile?tab=collected',
      },
      {
        text: 'Activity',
        route: '/myProfile?tab=activity',
      },
      {
        text: 'Settings',
        route: '/myProfile/settings',
      },
      {
        text: 'Support',
        route: '/myProfile',
      },
      {
        text: 'Logout',
        route: '/logout',
      },
    ],
  }),
  computed: {
    ...mapGetters({
      profile: 'user/profile',
    }),
    isMobileView() {
      return ['xs', 'sm'].includes(this.$vuetify.breakpoint.name);
    },
  },
  methods: {
    ...mapActions({ toggleMobileDrawer: 'toggleMobileDrawer', login: 'user/login' }),
    toggleSearchActive() {
      this.searchActive = !this.searchActive;
    },
    test() {
      console.log('testing routing');
    },
    search() {
      console.log('search');
      this.$router.push({ path: '/assets', query: { s: this.searchParam } });
      this.$router.go();
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
