<template>
  <div class="text-center hidden-sm-and-down">
    <template v-for="item in items">
      <template v-if="item.children && item.children.length">
        <v-menu
          :key="item.title"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="text-capitalize nav-item"
              text
              v-bind="attrs"
              v-on="on"
            >
              {{ item.title }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(childItem, index) in item.children"
              :key="index"
              v-bind="childItem.attrs"
            >
              <v-list-item-title>{{ childItem.title }}</v-list-item-title>
            </v-list-item>

            <v-dialog v-model="dialogs.newAtlantisCreator" width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item
                  v-if="item.key === 'new-atlantis-store'"
                  v-bind="attrs"
                  v-on="on"
                >
                  Apply New Atlantis Creator
                </v-list-item>
              </template>

              <new-atlantis-creator-form
                @close="dialogs.newAtlantisCreator = false"
                @save="dialogs.newAtlantisCreator = false"
              />
            </v-dialog>
          </v-list>
        </v-menu>
      </template>
      <template v-else>
        <v-btn
          :key="item.title"
          v-bind="item.attrs"
          class="text-capitalize nav-item"
          solo
          text
        >
          {{ item.title }}
        </v-btn>
      </template>
    </template>
  </div>
</template>

<script>
import navigationItems from '@/lib/navigationItems.json';

export default {
  components: {
    'new-atlantis-creator-form': () => import('@/features/forms/NewAtlantisCreatorForm.vue'),
  },
  data: () => ({
    items: navigationItems,
    dialogs: {
      newAtlantisCreator: false,
    },
  }),
};
</script>

<style lang="scss">
.nav-item {
  color: #573A00 !important;
}
</style>
