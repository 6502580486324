<template>
  <v-app-bar
    app
    clipped-left
    color="yellow"
  >
  <application-logo class="mr-4" />

    <app-bar-navigation />

    <v-spacer></v-spacer>
    <v-sheet
      :width="searchWidth"
      class="d-none d-md-block"
      color="transparent"
    >
    <!--Modify the website to add add tag details to it-->
      <v-text-field
        v-model="searchParam"
        class="mb-0 pb-0"
        color="white"
        flat
        hide-details
        label="Search items..."
        prepend-inner-icon="mdi-magnify"
        solo
        @keydown.enter="search"
      />
    </v-sheet>

    <v-divider
      class="mx-3 d-none d-md-flex"
      vertical
    />

    <app-bar-actions />
  </v-app-bar>
</template>

<script>
import AppBarNavigation from '@/features/app-structure/AppBarNavigation.vue';
import AppBarActions from '@/features/app-structure/AppBarActions.vue';

export default {
  name: 'AppBar',
  data() {
    return {
      searchParam: '',
    };
  },
  components: {
    AppBarNavigation,
    'app-bar-actions': AppBarActions,
  },
  methods: {
    search() {
      console.log('search');
      this.$router.push({ path: '/assets', query: { s: this.searchParam } });
      this.$router.go();
    },
  },
  computed: {
    searchWidth() {
      const isBreakpoint = (key) => this.$vuetify.breakpoint.name === key;

      if (isBreakpoint('lg')) return 500;
      if (isBreakpoint('xl')) return 600;

      return 300;
    },
  },
};
</script>
